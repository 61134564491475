import React, { useRef } from 'react'
import { Node, TreeState } from 'react-stately'
import styled from 'styled-components'
import { UNSTABLE_useSubmenuTrigger, useMenuItem } from '@react-aria/menu'
import { SvgChevronRight } from '@chilipiper/icons/src/design-system'
import { Box, Flex, RootPortal, Text } from '../../../core-components'
import { InternalPopover } from '../../../helpers/InternalPopover'
import { Icon } from '../../../../old/icon/Icon'
import { ThemeColors, ThemeShadows } from '../../../theme'
import { EndContentProvider } from '../../data'
import { useActionListContext } from './helpers'
import { SubmenuProvider } from './SubmenuContext'
import { UNSTABLE_useSubmenuTriggerState } from './useSubmenuTriggerState'

type PublicProps = {
  children?: React.ReactNode
  'data-test-id'?: string
  description?: string
  endContent?: React.ReactNode
  isDisabled?: boolean
  startIcon?: React.ComponentType
  title: string
}

type Props = PublicProps & {
  INTERNAL_isOpen?: boolean
  item: Node<unknown>
  state: TreeState<object>
}

export const CascadeItemInternal = ({
  title,
  children,
  item,
  state,
  startIcon,
  endContent,
  isDisabled,
  description,
  'data-test-id': dataTestId,
  INTERNAL_isOpen,
}: Props) => {
  const triggerRef = useRef<HTMLLIElement>(null)
  const submenuRef = useRef<HTMLUListElement>(null)
  const { listRef, menuTriggerState } = useActionListContext()
  const submenuTriggerState = UNSTABLE_useSubmenuTriggerState(
    { triggerKey: title },
    menuTriggerState
  )
  const { submenuTriggerProps, submenuProps, popoverProps } = UNSTABLE_useSubmenuTrigger(
    {
      node: item,
      parentMenuRef: listRef,
      submenuRef: submenuRef,
      isDisabled,
    },
    submenuTriggerState,
    triggerRef
  )
  const { menuItemProps, descriptionProps, labelProps } = useMenuItem(
    { key: item.key, ...submenuTriggerProps },
    state,
    triggerRef
  )
  const isOpen = submenuTriggerState.isOpen || Boolean(INTERNAL_isOpen)

  const { bg, boxShadow } = getColors(isOpen, isDisabled)

  return (
    <StyledBox
      as='li'
      px={5}
      boxShadow={boxShadow}
      bg={bg}
      {...menuItemProps}
      ref={triggerRef}
      data-test-id={dataTestId}
    >
      <Box boxShadow='border/list-item' py={4}>
        <Flex justifyContent='space-between' alignItems='center'>
          {startIcon && (
            <Icon
              icon={startIcon}
              mr={2}
              color={isDisabled ? 'icon/disabled' : 'icon/action-moderate'}
            />
          )}
          <Text
            display='block'
            flexGrow={1}
            py='1px'
            color={isDisabled ? 'text/action-disabled' : 'text/action-item'}
            textStyle='body-monoline'
            {...labelProps}
          >
            {title}
          </Text>
          {endContent && (
            <EndContentProvider value={{ iconColor: 'icon/indicator' }}>
              <Box ml={2} mr={4}>
                {endContent}
              </Box>
            </EndContentProvider>
          )}
          <Icon
            icon={SvgChevronRight}
            color={isOpen ? 'icon/indicator-selected' : 'icon/indicator'}
            w={4}
            h={4}
          />
        </Flex>
        {description && (
          <Text
            display='block'
            textStyle='detail-m'
            color='text/detail-moderate'
            mt={2}
            {...descriptionProps}
          >
            {description}
          </Text>
        )}
      </Box>
      {children && (
        <RootPortal>
          <SubmenuProvider value={submenuProps}>
            {isOpen && (
              <InternalPopover
                focusProps={{ contain: true, autoFocus: true, restoreFocus: true }}
                state={submenuTriggerState}
                {...popoverProps}
                isNonModal={false}
                placement='right top'
                popoverRef={submenuRef}
                triggerRef={triggerRef}
              >
                <Box boxShadow='border/popover' borderRadius={8}>
                  <Box borderRadius={8} overflow='hidden' bg='bg/dropdown'>
                    {children}
                  </Box>
                </Box>
              </InternalPopover>
            )}
          </SubmenuProvider>
        </RootPortal>
      )}
    </StyledBox>
  )
}

const StyledBox = styled(Box)`
  cursor: pointer;
  &[aria-disabled='true'] {
    cursor: default;
  }

  :focus,
  :hover {
    outline: none;
    background: ${({ theme }) => theme.colors['bg/list-item-hover']};
  }
`

export const CascadeItemPublic = CascadeItemInternal as unknown as React.FC<PublicProps>
const getColors = (
  isOpen: boolean,
  isDisabled?: boolean
): { bg: ThemeColors; boxShadow?: ThemeShadows } => {
  if (isDisabled) {
    return {
      bg: 'bg/disabled',
    }
  }
  if (isOpen) {
    return {
      bg: 'bg/list-item-selected',
      boxShadow: 'border/list-item-major-selected',
    }
  }
  return {
    bg: 'bg/list-item-unselected',
  }
}

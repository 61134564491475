// circular dependency between Tooltip and ActionButton cause arrow function components to be called before initialization
/* eslint-disable react/function-component-definition */
import React, { HTMLAttributes } from 'react'
import invariant from 'tiny-invariant'
import styled from 'styled-components'
import { createContextWithHook, typeValidator } from '@chilipiper/utils'
import { Box, Flex, Text } from '../../../core-components'
import { flattenChildren } from '../../helpers'
import { ActionBar } from '../../action/action-bar/ActionBar'
import { ThemeProviderClean } from '../../../../ThemeProviderClean'
import { darkTheme } from '../../../theme'

type Props = {
  children: React.ReactNode
  containerAttributes: Omit<HTMLAttributes<HTMLDivElement>, 'color'>
  'data-test-id'?: string
  tipRef: React.RefObject<HTMLDivElement>
}

export function Tip({ 'data-test-id': dataTestId, containerAttributes, children, tipRef }: Props) {
  return (
    <Box
      bg='bg/toast'
      borderRadius={10}
      overflow='hidden'
      ref={tipRef}
      data-test-id={dataTestId}
      data-react-aria-top-layer /* don't hide with ariaHideOutside because it can't be opened outside currently active dialog */
      {...containerAttributes}
    >
      <TipContentBaseProvider value={null}>
        <Box maxWidth={22}>
          <TipContent>{children}</TipContent>
        </Box>
      </TipContentBaseProvider>
    </Box>
  )
}

const TipContent = ({ children }: { children: React.ReactNode }) => {
  const flatChildren = flattenChildren(children)
  const allChildrenAreBodyComponents = flatChildren.every(child =>
    typeValidator(TipTitle, TipText, TipList)(child)
  )
  const allChildrenAreString = flatChildren.every(child => typeof child === 'string')

  if (allChildrenAreString) {
    return (
      <TipBody>
        <TipText>{children as string[]}</TipText>
      </TipBody>
    )
  }
  if (allChildrenAreBodyComponents) {
    return <TipBody>{children}</TipBody>
  }

  return <>{children}</>
}

export function TipMedia({
  image,
  video,
}:
  | { image?: never; video: { src: string } }
  | { image: { alt: string; src: string }; video?: never }) {
  useTipContentContext() // Check if used inside Tip as content

  const commonProps = {
    borderRadius: 8,
    width: 'calc(100% - 1rem)',
    display: 'block',
    mx: 2,
    mt: 2,
  }
  if (image) {
    return <Box as='img' {...commonProps} src={image.src} alt={image.alt} />
  }
  return (
    <Box as='video' {...commonProps} autoPlay muted loop>
      <source src={video.src} />
    </Box>
  )
}

export function TipCustomContent({ children }: { children: React.ReactNode }) {
  useTipContentContext() // Check if used inside Tip as content
  return (
    <Box px={5} py={4}>
      {children}
    </Box>
  )
}

export function TipBody({ children }: { children: React.ReactNode }) {
  invariant(
    flattenChildren(children).every(child => typeValidator(TipTitle, TipText, TipList)(child)),
    `TipBody: content must all be on of: TipTitle, TipText  TipList`
  )
  useTipContentContext() // Check if used inside Tip as content
  return (
    <Flex px={5} py={4} flexDirection='column' gap={3}>
      {children}
    </Flex>
  )
}

export function TipTitle({ children }: { children: string | string[] }) {
  useTipContentContext() // Check if used inside Tip as content

  return (
    <Text textStyle='heading-s' color='text/heading-major-oncolor'>
      {children}
    </Text>
  )
}

export function TipText({ children }: { children: string | string[] }) {
  useTipContentContext() // Check if used inside Tip as content

  return (
    <Text textStyle='body-multiline' color='text/body-major-oncolor' overflowWrap='break-word'>
      {children}
    </Text>
  )
}

export function TipList({ variant, items }: { items: string[]; variant: 'ordered' | 'unordered' }) {
  useTipContentContext() // Check if used inside Tip as content
  return (
    <StyledBoxList as={variant === 'ordered' ? 'ol' : 'ul'} pl={5} color='text/body-major-oncolor'>
      {items.map((item, index) => (
        <li key={index}>
          <Text color='text/body-major-oncolor' textStyle='body-multiline'>
            {item}
          </Text>
        </li>
      ))}
    </StyledBoxList>
  )
}

const StyledBoxList = styled(Box)`
  list-style: revert;
`

export function TipActionBar({ children }: { children: React.ReactNode }) {
  useTipContentContext() // Check if used inside Tip as content
  return (
    <ThemeProviderClean variation={darkTheme}>
      <ActionBar variant='nested'>{children}</ActionBar>
    </ThemeProviderClean>
  )
}

const { useTipContentContext, TipContentBaseProvider } = createContextWithHook<null>()('TipContent')
export const validateTipChildren = (
  content: React.ReactNode,
  allowActionBar: boolean,
  component: string
) => {
  const flatChildren = flattenChildren(content)
  const allChildrenAreString = flatChildren.every(child => typeof child === 'string')
  const allChildrenAreBodyComponents = flatChildren.every(child =>
    typeValidator(TipTitle, TipText, TipList)(child)
  )
  invariant(
    allChildrenAreString ||
      allChildrenAreBodyComponents ||
      flatChildren.every(
        child =>
          typeValidator(TipMedia, TipBody, TipCustomContent)(child) ||
          (allowActionBar && typeValidator(TipActionBar)(child))
      ),
    `${component}: content must all be either 
${component}.Media, ${component}.Body, ${component}.CustomContent${
      allowActionBar ? `, ${component}.ActionBar` : ''
    }
or
${component}.Title, ${component}.Text, ${component}.List
or 
string`
  )
}

import React, { ComponentProps, DOMAttributes } from 'react'
import noop from 'lodash/noop'
import { FocusableElement } from '@react-types/shared'
import { AriaButtonProps } from 'react-aria'
import {
  SvgCaution,
  SvgInform,
  SvgRemove,
  SvgSuccess,
  SvgWarning,
} from '@chilipiper/icons/src/design-system'
import invariant from 'tiny-invariant'
import { typeValidator } from '@chilipiper/utils'
import { Box, Flex, Text, VerticalDivider } from '../../../core-components'
import { ActionBarButton } from '../../action/action-bar/action-bar-button/ActionBarButton'
import { ActionBar } from '../../action'
import { darkTheme, ThemeColors, ThemeShadows, Variation } from '../../../theme'
import { ThemeProviderClean } from '../../../../ThemeProviderClean'
import { StartContent, StartContentProvider } from '../../data/start-content/StartContent'

type Props =
  | (
      | {
          actions?: Array<{
            'data-test-id'?: string
            onClick: () => void
            text: string
            variant: ComponentProps<typeof ActionBarButton>['variant']
          }>
          'data-test-id'?: string
          onClose?: () => void
          type?: 'inline'
        }
      | {
          actions: Array<{
            'data-test-id'?: string
            onClick: () => void
            text: string
            variant: ComponentProps<typeof ActionBarButton>['variant']
          }>
          closeButtonProps: AriaButtonProps<'button'>
          onClose: () => void
          titleProps: DOMAttributes<FocusableElement>
          type: 'floating'
        }
    ) & {
      description?: string
      isDismissable?: boolean
      startContent?: React.ReactNode
      title: string
      variant: 'neutral' | 'success' | 'inform' | 'warning' | 'caution'
    }

const AlertBannerStartContent = (props: React.ComponentPropsWithoutRef<typeof StartContent>) => (
  <StartContent {...props} />
)

export const AlertBanner = (props: Props) => {
  invariant(
    props.startContent === undefined || typeValidator(AlertBannerStartContent)(props.startContent),
    'startContent must be a AlertBannerStartContent component or undefined'
  )
  const {
    contentContainerBg,
    closeButtonProps,
    titleProps,
    borderRadius,
    theme,
    boxShadow,
    dataTestId,
    bg,
  } = typeProps(props)
  return (
    <Box
      flexGrow={1}
      bg={bg}
      data-test-id={dataTestId}
      overflow='hidden'
      borderRadius={borderRadius}
      boxShadow={boxShadow}
    >
      <ThemeProviderClean variation={theme}>
        <Flex
          bg={contentContainerBg}
          boxShadow={props.actions !== undefined ? 'border/row' : undefined}
        >
          <Flex py={3} px={6} gap={3} flexGrow={1}>
            <StartContentProvider value={{ iconColor: iconColorMap[props.variant] }}>
              <Box mt={1} flexShrink={0}>
                {props.startContent ? (
                  props.startContent
                ) : (
                  <StartContent variant='icon' icon={iconMap[props.variant]} />
                )}
              </Box>
            </StartContentProvider>
            <Flex flexDirection='column' gap={1}>
              <Text textStyle='body-multiline' color='text/body-major' {...titleProps}>
                {props.title}
              </Text>
              {props.description && (
                <Text textStyle='body-multiline' color='text/body-moderate'>
                  {props.description}
                </Text>
              )}
            </Flex>
          </Flex>
          {props.isDismissable && (
            <Flex>
              <VerticalDivider />
              <ActionBar variant='inRow'>
                <ActionBar.IconButton
                  icon={SvgRemove}
                  onClick={props.onClose ?? noop}
                  ariaProps={closeButtonProps}
                />
              </ActionBar>
            </Flex>
          )}
        </Flex>
        {props.actions !== undefined && (
          <ActionBar variant='nested'>
            {props.actions.map(actionObject => (
              <ActionBar.Button
                key={actionObject.text}
                onClick={() => {
                  actionObject.onClick()
                  props.onClose?.()
                }}
                variant={actionObject.variant}
                data-test-id={actionObject['data-test-id']}
              >
                {actionObject.text}
              </ActionBar.Button>
            ))}
          </ActionBar>
        )}
      </ThemeProviderClean>
    </Box>
  )
}

const variantBg = {
  neutral: 'bg/container-highlight',
  success: 'bg/feedback-success',
  inform: 'bg/feedback-inform',
  warning: 'bg/feedback-warning',
  caution: 'bg/feedback-caution',
} satisfies Record<Props['variant'], ThemeColors>

const iconColorMap = {
  inform: 'icon/inform',
  warning: 'icon/warning',
  success: 'icon/success',
  caution: 'icon/error',
  neutral: 'icon/indicator',
} as const

const iconMap = {
  inform: SvgInform,
  warning: SvgWarning,
  success: SvgSuccess,
  caution: SvgCaution,
  neutral: SvgInform,
}

const typeProps = (
  props: Props
): {
  bg?: ThemeColors
  boxShadow?: ThemeShadows
  borderRadius?: number
  dataTestId?: string
  theme?: Variation
  titleProps: DOMAttributes<FocusableElement>
  closeButtonProps?: AriaButtonProps<'button'>
  contentContainerBg?: ThemeColors
} => {
  if (props.type === 'floating') {
    return {
      bg: 'bg/toast',
      boxShadow: 'border/popover',
      borderRadius: 12,
      theme: darkTheme,
      titleProps: props.titleProps,
      closeButtonProps: props.closeButtonProps,
    }
  }
  return {
    dataTestId: props['data-test-id'],
    contentContainerBg: variantBg[props.variant],
    titleProps: {},
  }
}

AlertBanner.StartContent = AlertBannerStartContent
